<template>
  <div class="form-component">
    <h2>{{ t('steps.tiles.title') }}</h2>
    <p v-html="t('steps.tiles.description')"></p>
    <div v-if="surfaces.length" class="surfaces">
      <TilesSelectTemplateSk
        v-if="currentLanguage === 'sk'"
        :is-selected="isSelected"
        :selected-tile="selectedTile"
        :update="update"
        :surfaces="surfaces"
      />
      <TilesSelectTemplate
        v-else
        :is-selected="isSelected"
        :selected-tile="selectedTile"
        :update="update"
        :surfaces="surfaces"
      />
    </div>
    <SkeletonLoader v-else :items="4" :items-per-row="4" />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import useGtmEvent from '@/hooks/gtm'
import { useI18n } from 'vue-i18n'

import SkeletonLoader from '@/components/Global/SkeletonLoader.vue'
import TilesSelectTemplate from '@/components/Calculator/TilesSelectTemplate.vue'
import TilesSelectTemplateSk from '@/components/Calculator/TilesSelectTemplateSk.vue'

export default defineComponent({
  name: 'TilesSelect',
  components: {
    SkeletonLoader,
    TilesSelectTemplate,
    TilesSelectTemplateSk
  },
  emits: ['on-select'],
  props: {
    surfaces: {
      type: Array,
      required: true
    },
    current: {
      type: [Object, Boolean],
      required: true
    }
  },
  setup(props, { emit }) {
    const selectedTile = computed(() => props.current)
    const { trackData } = useGtmEvent()
    const { t } = useI18n()
    const currentLanguage = localStorage.getItem('language') || process.env.VUE_APP_DEFAULT_LOCALE

    const isSelected = ({ id }) => {
      return selectedTile.value.id === id
    }

    const update = tile => {
      if (!tile) return

      trackData({
        category: 'Cserép módosítása',
        label: `${tile.title} ${tile.color}`
      })

      emit('on-select', tile)
    }

    return {
      isSelected,
      selectedTile,
      update,
      t,
      currentLanguage
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/base/_variables.scss';

.form-component {
  :deep(.skeleton) {
    height: 600px;
    align-items: flex-start;
  }
}

:deep {
  h2 {
    @media (max-width: 991px) {
      margin-bottom: 5px;
      margin-top: 30px;
    }

    @media (min-width: 992px) {
      margin-bottom: 8px;
      margin-top: 60px;
    }
  }

  .surfaces {
    @media (max-width: 991px) {
      margin-top: 35px;
      margin-bottom: 60px;
    }

    @media (min-width: 992px) {
      margin-top: 50px;
      margin-bottom: 90px;
    }
  }

  .surface {
    &:not(:last-child) {
      @media (max-width: 991px) {
        .surface__content {
          border-bottom: 3px solid $white-two;
          margin-bottom: 30px;
        }
      }
    }

    &__feature {
      margin-bottom: 10px;
    }

    .feature {
      &__icon {
        min-width: 16px;
      }
    }

    &__block {
      height: 100%;
      position: relative;

      @media (min-width: 992px) {
        &::after {
          content: '';
          position: absolute;
          right: -15px;
          top: 0;
          width: 2px;
          height: 100%;
          display: block;
          background-image: linear-gradient(to bottom, $white-two 37%, $white);
        }
      }
    }

    &__title {
      font-family: $font-family-heading-extrabold;
      font-size: 20px;
      font-weight: 800;
      margin: 0 0 0 5px;

      @media (min-width: 992px) {
        font-size: 24px;
        margin: 5px 0 0;
      }
    }

    &__icon {
      max-width: 25px;

      @media (min-width: 992px) {
        max-width: 100%;
      }
    }

    &__features {
      list-style-type: none;
      margin: 0;
      padding: 0;

      .feature__label {
        font-size: 12px;
        color: $black-two;
        letter-spacing: 0;
        padding-left: 8px;
        line-height: 1.4;
      }
    }
  }

  .tiles-list {
    margin: 30px 0 35px;

    @media (max-width: 991px) {
      padding: 0 0 30px;
      margin: 0;
      overflow-x: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .tiles-list-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    border: 4px solid rgba(0, 0, 0, 0);
    cursor: pointer;
    padding: 10px 5px;
    margin: 5px;
    position: relative;
    min-width: 140px;

    @media (min-width: 992px) {
      padding: 10px;
      //flex: 0 0 calc(50% - 10px);
      min-width: auto;
      //max-width: calc(50% - 10px);
    }

    .badges {
      position: absolute;
      top: 15px;
      right: 15px;
      display: flex;
      flex-direction: column;
    }

    &__color,
    &__price {
      font-family: $font-family-heading;
      font-weight: bold;
      font-size: 14px;
    }

    &__title {
      display: block;
      padding-bottom: 12px;
      font-family: $font-family-base;
      font-size: 14px;
      letter-spacing: 0;
      text-transform: uppercase;
    }

    &__image {
      width: 100%;
    }

    &__price {
      margin-top: auto;
    }

    &.selected {
      border-color: #fdab1c;
    }
  }

  .icon-info-gray {
    margin-left: auto;
    width: 20px;
    line-height: 1;

    &:after {
      height: 20px;
    }
  }

  .surface__tooltip-trigger {
    appearance: none;
    width: 0;
    position: absolute;

    &:not(:checked) ~ .surface__tooltip-overlay,
    &:not(:checked) ~ .surface__tooltip-content {
      display: none;
    }

    ~ label {
      cursor: pointer;
      margin-bottom: 0;
    }

    ~ .surface__tooltip-overlay {
      position: fixed;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(17, 17, 17, 0.7);
    }

    ~ .surface__tooltip-content {
      position: fixed;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100vw - 30px);
      background: #fff;
      padding: 40px 24px 24px;

      .icon-close {
        display: block;
        width: 16px;
        height: 16px;
        filter: invert(1);
        position: absolute;
        top: 24px;
        right: 24px;
      }

      label {
        cursor: pointer;
      }
    }
  }
}
</style>
