<template>
  <div class="row">
    <div v-for="surface in surfaces" :key="surface.id" class="col-lg-3 surface">
      <div class="surface__block d-sm-flex flex-sm-column justify-content-sm-between">
        <div class="surface__content">
          <div class="surface__header d-flex d-lg-block align-items-center">
            <img class="surface__icon" :src="surface.image" :alt="surface.title" />
            <h3 class="surface__title">{{ surface.title }}</h3>
            <div class="ml-auto position-relative d-lg-none">
              <input type="checkbox" :id="`surface-${surface.id}`" class="surface__tooltip-trigger" />
              <label :for="`surface-${surface.id}`">
                <em class="icon-info-gray" />
              </label>
              <div class="surface__tooltip-overlay"></div>
              <div class="surface__tooltip-content text-left">
                <div class="d-flex align-items-center mb-3">
                  <img class="surface__icon mr-2" :src="surface.image" :alt="surface.title" />
                  <h3 class="surface__title">{{ surface.title }}</h3>
                </div>
                <ul v-if="surface.features" class="surface__features">
                  <li v-for="(feature, index) in surface.features" :key="index" class="surface__feature d-flex">
                    <SurfaceFeatureIcon :color="feature.color" />
                    <span class="feature__label">{{ feature.label }}</span>
                  </li>
                </ul>
                <label :for="`surface-${surface.id}`">
                  <em class="icon-close" />
                </label>
              </div>
            </div>
          </div>

          <div class="tiles-list d-flex flex-sm-wrap">
            <div
              v-for="tile in surface.tiles"
              :key="tile.id"
              @click="update(tile)"
              class="tiles-list-item"
              :class="{ selected: isSelected(tile) }"
            >
              <img :src="tile.image" :alt="tile.title" class="tiles-list-item__image" />
              <div>
                <strong class="tiles-list-item__color">{{ tile.color }}</strong>
                <span class="tiles-list-item__title">{{ tile.title }}</span>
              </div>
              <strong class="tiles-list-item__price">{{ tile.gross }} {{ t('general.currency') }}</strong>
              <div class="badges">
                <Badge v-for="(badge, index) in tile.badges" :key="index" :badge="badge" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div v-for="surface in surfaces" :key="surface.id" class="col-lg-3 surface">
      <ul v-if="surface.features" class="surface__features text-left d-none d-lg-block">
        <li v-for="(feature, index) in surface.features" :key="index" class="surface__feature d-flex">
          <SurfaceFeatureIcon :color="feature.color" />
          <span class="feature__label">{{ feature.label }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import SurfaceFeatureIcon from '@/components/Calculator/SurfaceFeatureIcon.vue'
import Badge from '@/components/Global/Badge.vue'

export default defineComponent({
  name: 'TilesSelectTemplate',
  components: {
    SurfaceFeatureIcon,
    Badge
  },
  props: {
    surfaces: {
      type: Array,
      required: true
    },
    update: {
      type: Function,
      required: true
    },
    selectedTile: {
      type: [Object, Boolean],
      required: true
    },
    isSelected: {
      type: Function,
      required: true
    }
  },
  setup() {
    const { t } = useI18n()

    return {
      t
    }
  }
})
</script>

<style scoped lang="scss">
.tiles-list {
  @media (max-width: 991px) {
    padding-top: 20px;
  }
}

.tiles-list-item {
  max-width: 140px;

  @media (min-width: 992px) {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
}
</style>
